// import DebugConsole from '@helper/functions/console';

import {
  invoiceAdminAPI,
  paymentAPI,
} from './api';
import { handleErrorMessage } from './shared';

export async function getGenerateCaptureContext(healthEntityCode, paymentType, pbrc=false) {
  try {
    let url = `/hppapi/payment/generate-capture-context`
    /*
        if(pbrc){
      url = `/pbrc/hppapi/payment/generate-capture-context`
    }
    if(international){
      url =`/intapi/hppapi/payment/generate-capture-context`
    }

    */
    const { data } = await paymentAPI.get(url, {
      params: {
        healthEntityCode: healthEntityCode,
        paymentType: paymentType,
      },
    });
    return data.data;
  } catch (error) {
    handleErrorMessage(error);
    return false;
  }
}

export const getGenerateKey = async (healthEntityCode, paymentType, pbrc=false) => {
  try {
    let url = `/hppapi/payment/generate-key`
    /*
        if(pbrc){
      url = `/pbrc/hppapi/payment/generate-key`
    }

    */
    const { data } = await paymentAPI.get(url, {
      params: {
        healthEntityCode: healthEntityCode,
        paymentType: paymentType,
      },
    });
    return data.data;
  } catch (error) {
    handleErrorMessage(error);
    return false;
  }
};

export const postFlexProcessPayment = async (body, pbrc=false) => {
  try {
    let data;

    //replace , to whitespace on comments
    const updatedCommentsBulk = body?.activeInvoice?.terminalBulk?.comments?.replace(/,/g, ' ') || '';

    if(updatedCommentsBulk !== ''){
      body.activeInvoice.terminalBulk.comments = updatedCommentsBulk;
      body.activeInvoice.formData.comments = updatedCommentsBulk;

    }

    const updatedCommentsTerminal = body?.activeInvoice?.terminal?.comments?.replace(/,/g, ' ') || '';

    if(updatedCommentsTerminal !== ''){
      body.activeInvoice.terminal.comments = updatedCommentsTerminal;
    }

    if (
      body.paymentMethod === 'cash' ||
      body.paymentMethod === 'cheque' ||
      body.paymentMethod === 'terminal'
    ) {
      console.log('CASHIER PAYMENT');
      let url = `/invapi/cashier/payment`
      /*
            if(pbrc){
        url = `/invapi/cashier/payment`
      }
      */
      const response = await invoiceAdminAPI(body.healthEntityCode).post(
        url,
        body,
      );
      data = response;
    } else {
      let url = `/hppapi/payment/process`
      /*
            if(pbrc){
        url = `/pbrc/hppapi/payment/process`
      }

      if(international){
        url = `/intapi/hppapi/payment/process`
      }

      */
      const response = await paymentAPI.post(url, body);
      data = response.data;
    }
    return data.data;
  } catch (error) {
    handleErrorMessage(error);
    return false;
  }
};

/**
 * Calls backend to initiate Step 1: Setup Payer Auth
 * @see https://developer.cybersource.com/docs/cybs/en-us/payer-authentication/developer/all/rest/payer-auth/pa-ccdc-api-intro/pa-ccdc-setup-service-intro.html
 * @see /model/cybersourceservices/payment/helper/setupPayerAuthentication.js
 */
export const postSetupPayerAuth = async (body, pbrc=false) => {
  try {
      let url = `/hppapi/payment/setup-payer-auth`
      /*
            if(pbrc){
        url = `/pbrc/hppapi/payment/setup-payer-auth`
      }

      if(international){
        url = `/intapi/hppapi/payment/setup-payer-auth`
      }
      */
    const { data }: { data: { data: SetupPayerAuthResponse } } = await paymentAPI.post(
      url,
      body,
    );
    return data.data;
  } catch (error) {
    handleErrorMessage(error);
    return false;
  }
};

/**
 * Calls backend to initiate Step 3: Payer Authentication Check Enrollment Service
 * @see https://developer.cybersource.com/docs/cybs/en-us/payer-authentication/developer/all/rest/payer-auth/pa2-ccdc-enroll-intro.html
 * @see /model/cybersourceservices/payment/helper/checkPayerAuthenticationEnrollment.js
 */
export const postCheckPayerAuthEnrollment = async (body: ValidatePayerAuthEnrollmentParam, pbrc=false) => {
  try {
    let url = `/hppapi/payment/check-payer-auth-enrollment`
    /*
        if(pbrc){
      url = `/pbrc/hppapi/payment/check-payer-auth-enrollment`
    }
    if(international){
      url = `/intapi/hppapi/payment/check-payer-auth-enrollment`
    }

    */
    const { data }: { data: { data: ValidatePayerAuthEnrollmentResponse } } = await paymentAPI.post(
      url,
      body,
    );
    return data.data;
  } catch (error) {
    handleErrorMessage(error);
    return false;
  }
};

export const postValidatePayerAuth = async (body, pbrc=false) => {
  try {
    let url = `/hppapi/payment/validate-payer-auth`
    /*
        if(pbrc){
      url = `/pbrc/hppapi/payment/validate-payer-auth`
    }

    if(international){
      url = `/intapi/hppapi/payment/validate-payer-auth`
    }
    */
    const { data }: { data: { data: ValidateAuthResults } } = await paymentAPI.post(
      url,
      body,
    );
    return data.data;
  } catch (error) {
    handleErrorMessage(error);
    return false;
  }
};
